'use strict';

angular.module('enervexSalesappApp').controller('CustomerCompaniesCtrl', function($scope, $http, Auth, CustomerCompany, _) {
	$scope.offset = 0;
	$scope.limit = 100;

	$scope.companies = [];
	$scope.offsetCompanies = 0;
	$scope.limitCompanies = 100;


	$scope.selectFilter = function() {
		var regex = null;
		if ($scope.accountNameFilter && $scope.accountNameFilter != ""){
			regex = new RegExp($scope.accountNameFilter, "i")
		}
		$scope.companies = _.filter($scope.allCompanies, function(company){
			var typeTest = true;
			if (regex) {
				return regex.test(company.Company) && typeTest
			} else {
				return typeTest
			}
		})
	}
	function fetchCompanies() {
		CustomerCompany.query({
			offset:$scope.offsetCompanies,
			limit: $scope.limitCompanies,
			orderby: "Company"
		}).$promise.then(function(companies){
			if (companies.length > 0) {
				$scope.companies = $scope.companies.concat(companies);
				$scope.allCompanies = $scope.companies;
				$scope.offsetCompanies = $scope.offsetCompanies + $scope.limitCompanies;
				fetchCompanies();
			}
		});
	}
	fetchCompanies();
	// $scope.sortType = 'name';
	$scope.sortReverse = false;
});
